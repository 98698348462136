<template>
  <div class="page">
    <div class="wrapper illustration illustration_form">
      <Backlink title="detail" service="refCredit" backlink="/refinance-credit/members" />
      <div class="content">
        <div class="content__container">
          <form @submit.prevent="submitHandler">
            <p class="question">{{ "c_s1p2_title" | localize }}</p>

            <div class="form__group">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date_formatted"
                      class="v-input-calendar input_number"
                      v-mask.hide-on-empty="dateMask"
                      @focus="date = parseDate(date_formatted)"
                      @blur="date = parseDate(date_formatted); $v.date.$touch()"
                      :class="{ invalid: ($v.date.$dirty && !$v.date.required) || ($v.date.$dirty && !$v.date.validDate) }"
                      :hint="hintArray($v.date, [{rule: $v.date.required, message: 'form_reqiered'}, {rule: $v.date.validDate, message: 'form_wrong_date'}]) | localize"
                      :placeholder="'s1p2_date' | localize"
                      outlined
                      persistent-hint
                      append-icon="icon-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @change="checkForm($v.date)"
                  no-title
                  full-width
                  :min="dateFrom"
                  @input="menu = false; date_formatted = formatDate(date)"
                ></v-date-picker>
              </v-menu>
            </div>

            <p class="question">{{ "rc_s2p2_title" | localize }}</p>

            <div class="form__group">
              <v-select
                v-model="amount"
                @blur="$v.amount.$touch()"
                @change="checkForm($v.amount)"
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                :class="{ invalid: $v.amount.$dirty && !$v.amount.required }"
                :placeholder="'select' | localize"
                :menu-props="{ offsetY: true }"
                append-icon="icon-arrow-down"
                ref="amount"
                outlined
              ></v-select>
            </div>
            <button
              type="submit"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
            >
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VueCookies from 'vue-cookies'
import Backlink from '@/components/base/Backlink.vue'
import { formMixin } from '@/mixins/formMixin.js'
import { saveMixin } from '@/mixins/saveMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'

const refCredit = VueCookies.get('refCredit') || {}

export default {
  name: 'Refinance_credit_amount',
  components: { Backlink },
  mixins: [formMixin, saveMixin, commonMixin],
  data: () => ({
    amount: refCredit.amount || null,
    date: refCredit.date || null,
    dateFrom: new Date().toISOString().substr(0, 10),
    date_formatted: refCredit.date ? this.formatDate(refCredit.date || new Date().toISOString().substr(0, 10)) : null,
    dateMask: [/\d/, /\d/, ' / ', /\d/, /\d/, ' / ', /\d/, /\d/, /\d/, /\d/],
    menu: false
  }),
  validations: {
    date: { required, validDate: v => formMixin.methods.isValidDate(v) },
    amount: { required }
  },
  methods: {
    submitHandler () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }
      refCredit.date = this.date
      refCredit.amount = this.amount
      this.$cookies.set('refCredit', refCredit)
      this.saveServiceInfo('refCredit', false, { date: this.date })
      this.$router.push({ name: 'Refinance Credit: About Credits', params: { amount: this.amount } })
    }
  }
}
</script>
